import React from "react"
import SEO from "../components/seo"
import { graphql} from "gatsby"
import CaseStudyTeaserImage from "../components/caseStudyTeaserImage/caseStudyTeaserImage"


const WorkPage = ({ data: { caseStudies, creativeCaseStudies }, location}) => {
  return (
    <>
      <SEO
        titleOverride={"Our Work & Case Studies"}
        descriptionOverride={"View our digital marketing and creative services work at Adtrak. Over the years we have created many digital marketing strategies."}
        pathnameOverride={location.pathname}
      />

      <section className="mt-0 mb-16 md:mt-6">
        <div className="container">
          <div className="w-full mx-auto md:w-10/12">
            <div className="w-full md:w-9/12 lg:w-7/12">
              <h1>Our Work</h1>
              <div className="content content--reset">
                <p>We’re experts in design, marketing and brand and use our skills collaboratively to generate fantastic results. Find out how we’ve helped our clients succeed.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className="py-4 mb-12 overflow-hidden lg:mb-24">
        <div className="container">
          <div className="w-full mx-auto md:w-10/12">
            <span className="inline-block mb-2 mr-3 text-lg">Filter by:</span>
            <input type="radio" id="reset" className="mr-1 filter-input" name="filters" />
            <label className="inline-block px-2 py-1 mb-3 mr-3 border cursor-pointer border-secondary hover:opacity-50 focus:opacity-50 text-secondary" htmlFor="reset">All</label>

            <input type="radio" id="web-design" className="mr-1 filter-input" name="filters" />
            <label className="inline-block px-2 py-1 mb-3 mr-3 border cursor-pointer border-secondary hover:opacity-50 focus:opacity-50 text-secondary" htmlFor="web-design">Web Design</label>

            <input type="radio" id="ecommerce-agency" className="mr-1 filter-input" name="filters" />
            <label className="inline-block px-2 py-1 mb-3 mr-3 border cursor-pointer border-secondary hover:opacity-50 focus:opacity-50 text-secondary" htmlFor="ecommerce-agency">E-Commerce</label>
            
            <input type="radio" id="seo" className="mr-1 filter-input" name="filters"/>
            <label className="inline-block px-2 py-1 mb-3 mr-3 border cursor-pointer border-secondary hover:opacity-50 focus:opacity-50 text-secondary" htmlFor="seo">SEO</label>

            <input type="radio" id="ppc" className="mr-1 filter-input" name="filters"/>
            <label className="inline-block px-2 py-1 mb-3 mr-3 border cursor-pointer border-secondary hover:opacity-50 focus:opacity-50 text-secondary" htmlFor="ppc">PPC</label>
            
            <input type="radio" id="social-marketing" className="mr-1 filter-input" name="filters"/>
            <label className="inline-block px-2 py-1 mb-3 mr-3 border cursor-pointer border-secondary hover:opacity-50 focus:opacity-50 text-secondary" htmlFor="social-marketing">Social</label>
            
            <input type="radio" id="branding" className="mr-1 filter-input" name="filters"/>
            <label className="inline-block px-2 py-1 mb-3 mr-3 border cursor-pointer border-secondary hover:opacity-50 focus:opacity-50 text-secondary" htmlFor="branding">Branding</label>
            
            <input type="radio" id="photography" className="mr-1 filter-input" name="filters"/>
            <label className="inline-block px-2 py-1 mb-3 mr-3 border cursor-pointer border-secondary hover:opacity-50 focus:opacity-50 text-secondary" htmlFor="photography">Photo</label>
            
            <input type="radio" id="videography" className="mr-1 filter-input" name="filters"/>
            <label className="inline-block px-2 py-1 mb-3 mr-3 border cursor-pointer border-secondary hover:opacity-50 focus:opacity-50 text-secondary" htmlFor="videography">Video</label>
            
            <div className="flex flex-wrap mt-4 md:-mx-1">
            {caseStudies.edges.map(({ node }, index) => {
                let servicesClassArray = [];
                let creativeServicesClassArray = [];
                node.serviceFilters.forEach(item => {
                  servicesClassArray.push(item.slug + ' ')
                });
                node.creativeServiceFilters.forEach(item => {
                  creativeServicesClassArray.push(item.slug + ' ')
                });
                
                return(
                  <div className={`w-full md:w-1/2 xl:w-1/3 md:px-1 pb-2 filter-item ${ servicesClassArray.toString().replace(/,/g , " ") + ' ' + creativeServicesClassArray.toString().replace(/,/g , " ") }`} key={index}>
                    <CaseStudyTeaserImage
                      url={`/work/${node.slug}`}
                      image={node.teaserImage.fluid}
                      title={node.title}
                      location={node.location}
                  />
                  </div>
                )
              })}
              
              {creativeCaseStudies.edges.map(({ node }, index) => {
                let creativeServicesClassArray = [];
                let creativeCreativeServicesClassArray = [];
                node.serviceFilters.forEach(item => {
                  creativeServicesClassArray.push(item.slug + ' ')
                });
                node.creativeServiceFilters.forEach(item => {
                  creativeCreativeServicesClassArray.push(item.slug + ' ')
                });

                return(
                  <div className={`w-full md:w-1/2 xl:w-1/3 md:px-1 pb-2 filter-item ${ creativeServicesClassArray.toString().replace(/,/g , " ") + ' ' + creativeCreativeServicesClassArray.toString().replace(/,/g , " ") }`} key={index}>
                    <CaseStudyTeaserImage
                      url={`/work/${node.slug}`}
                      image={node.teaserImage.fluid}
                      title={node.title}
                      location={node.location}
                    />
                  </div>
                )
              })}
              
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default WorkPage

export const query = graphql`
  query WorkPageQuery {
    caseStudies: allDatoCmsCaseStudy(sort: { fields: [meta___createdAt], order: DESC }) {
      edges {
        node {
          meta {
            createdAt
          }
          title
          location
          slug
          creativeServiceFilters {
            title
            slug
          }
          serviceFilters {
            title
            slug
          }
          teaserImage {
            fluid(
              maxWidth: 640
              maxHeight: 640
              imgixParams: {h: "640", w: "640", fit: "crop", crop: "faces, edges"}) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
      }
    }
    creativeCaseStudies: allDatoCmsCreativeCaseStudy(sort: { fields: [meta___createdAt], order: DESC }) {
      edges {
        node {
          meta {
            createdAt
          }
          title
          location
          slug
          creativeServiceFilters {
            title
            slug
          }
          serviceFilters {
            title
            slug
          }
          teaserImage {
            fluid(
              maxWidth: 640
              maxHeight: 640
              imgixParams: {h: "640", w: "640", fit: "crop", crop: "faces, edges"}) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
      }
    }
    creativeServices: allDatoCmsCreativeService(filter: { root: { eq: true } }) {
      edges {
        node {
          title
          slug
        }
      }
    }
    digitalServices: allDatoCmsService(filter: { root: { eq: true } }) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`